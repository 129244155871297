import { routeNames } from '../../../../config/routes';
import { RouterConfiguration } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import './sitedetailstanks.css';

export class SiteDetailsTanks {
  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: 'tankDetails/:siteChannelId/:tab?',
        name: routeNames.sitedetailsTanksDetails,
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailstanks/sitedetailstanksdetails/sitedetailstanksdetails',
          'sitedetails-tanks-details'
        )
      },
      {
        route: 'channelDetails/:siteChannelId/:tab?',
        name: routeNames.sitedetailsChannelDetails,
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailstanks/sitedetailschanneldetails/sitedetailschanneldetails',
          'sitedetails-channel-details'
        )
      },
      {
        route: '',
        name: routeNames.sitedetailsChannelsList,
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailstanks/sitedetailschannelslist/sitedetailschannelslist',
          'sitedetails-channels-list'
        )
      }
    ]);
  }
}
